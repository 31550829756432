import React from "react";
import { Button, Layout, Result } from "antd";
import MyMenu from "./menuItem";
import getSelectedContent from "./../utility/menuController";
import { DataConsumer } from "./../dataProvider/controller";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretSquareDown,
  faCaretSquareUp,
  faCoffee,
  faUpload,
  faFilter,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import ErrorBoundary from "../utility/ErrorBoundary";

require("./../style/sider.css");

const { Content, Sider } = Layout;

export default class MySider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false, selectedMenu: "0" };
  }

  onCollapse = (collapsed) => this.setState({ collapsed });
  triggerSider = ({ key }) => {
    //console.log(key === "Leave/Absent")
    // return localStorage.setItem("selectedMenu", key)
    console.log(key);
  };

  collapseMenu = () => {
    this.setState((prevState) => ({ collapsed: !prevState.collapsed }));
  };

  render() {
    // //console.log("inside MySider", this.props)
    return (
      <DataConsumer>
        {({ selectedMenu }) => (
          <Layout>
            <Sider
              className="sider-container"
              breakpoint="md"
              onBreakpoint={(broken) => {
                "md";
              }}
              onCollapse={(collapsed, type) => {}}
              collapsed={this.state.collapsed}
            >
              <MyMenu
                triggerSider={this.triggerSider}
                selectedKeysPath={this.props.location.pathname}
                hidden={this.state.collapsed}
              />
              <Button className="hide-button-nav" onClick={this.collapseMenu}>
                <FontAwesomeIcon
                  icon={faChevronCircleRight}
                  className={this.state.collapsed ? "hidden-nav" : "show-nav"}
                />

                {/* {this.state.collapsed ? ">>" : "<<"}
                <p style={{ fontSize: 10, textAlign: "center" }}>
                  {this.state.collapsed ? "Show menu" : "Hide menu"}
                </p> */}
              </Button>
              <p className="version-biponon">V 5.59</p>
            </Sider>
            <ErrorBoundary>
              <Content style={{ padding: "0 0px" }}>
                {getSelectedContent(selectedMenu)}
              </Content>
            </ErrorBoundary>
          </Layout>
        )}
      </DataConsumer>
    );
  }
}
